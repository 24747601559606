import { Text } from '@chakra-ui/react';

const CardTitle = ({ title }: { title: string }) => {
  return (
    <Text isTruncated fontSize='lg' fontWeight='semibold' color='text.primary' lineHeight='7'>
      {title}
    </Text>
  );
};
export default CardTitle;
